














































import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";

@Component({
  name: "VerifyCode",
  components: {},
})
export default class extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  private valid = false;
  private codeSent = false;
  private loading = false;
  private code = "";

  private async verifyCode() {
    this.loading = true;
    try {
      if (this.code.length != 4) {
        alert("کد اشتباه است");
        this.loading = false;
        return;
      }
      await UserModule.VerifyCode({ code: this.code, mobile: UserModule.mobile });
      this.loading = false;
      if (UserModule.verfied) {
        this.$router.push("/dashboard/sshkeys");
      }
    } catch (error) {
      this.loading = false;
    }
  }
  private validationForm() {
    this.$refs.form.validate();
  }
}
